<template>
  <div style="padding:16px" v-loading.fullscreen.lock="fullscreenLoading">
    <h3 style="margin-bottom:50px">{{ detail.memo }}</h3>
    <h4>工单信息</h4>
    <div class="fielList">
      <p><span>需求方联系人：</span>{{ detail.demandPersonInfo || '--' }}</p>
    </div>
    <div class="fielList">
      <p><span>腾讯工单号：</span>{{ detail.ticketId || '--' }}</p>
      <p><span>PO订单号：</span>{{ detail.orderNo || '--' }}</p>
    </div>
    <div class="fielList">
      <p><span>订单创建时间：</span>{{ detail.createTime || '--' }}</p>
      <p><span>超时时间：</span>{{ detail.slaTime || '--' }}</p>
    </div>
    <div class="fielList">
      <p><span>专项(服务项)：</span>{{ detail.svrItem || '--' }}</p>
      <p><span>待操作设备数量：</span>{{ detail.num || '--' }}</p>
      <p><span>服务信息：</span>{{ detail.serviceInfo || '--' }}</p>
    </div>
    <div class="fielList">
      <p><span>园区：</span>{{ detail.campusName || '--' }}</p>
      <p><span>一级机房：</span>{{ detail.idcpName || '--' }}</p>
      <p><span>机房单元：</span>{{ detail.idcName || '--' }}</p>
    </div>
    <div class="fielList">
      <p><span>服务商接口人：</span>{{ detail.servicePersonInfo || '--' }}</p>
      <p><span>服务商商务经理：</span>{{ detail.businessManager || '--' }}</p>
    </div>

    <h4>结单信息</h4>
    <div class="fielList1">
      <p><span>工单结单时间：</span>{{ detail.cEndTime || '--' }}</p>
      <p><span>处理工程师名称：</span>{{ detail.engineerName || '--' }}</p>
      <p><span>任务实际处理人：</span>{{ detail.cUser || '--' }}</p>
    </div>
    
    <h4>设备详情</h4>
    <el-table border :data="detail.details" style="width: 1200px">
      <el-table-column prop="ticketId" label="腾讯工单号"> </el-table-column>
      <el-table-column prop="assetId" label="固资号"> </el-table-column>
      <el-table-column prop="sn" label="SN"> </el-table-column>
      <el-table-column prop="model" label="机型"> </el-table-column>
      <el-table-column prop="posName" label="机位"> </el-table-column>
      <el-table-column prop="rackName" label="机架"> </el-table-column>
      <el-table-column prop="idcName" label="机房单元"> </el-table-column>
      <el-table-column prop="svrType" label="设备类型"> </el-table-column>
    </el-table>

  </div>
</template>

<script>
import { itPOserviceDetail } from '@/api/monitor';
export default {
  name: 'itPOserviceDetail',
  data() {
    return {
      fullscreenLoading: false,
      detail: {},
    };
  },
  created() {
    this.itPOserviceDetail();
  },
  methods: {
    //详情接口
    itPOserviceDetail() {
      this.fullscreenLoading = true;
      itPOserviceDetail({ticketId:this.$route.query.ticketId})
        .then((res) => {
          this.detail = res.data;
          this.fullscreenLoading = false;
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
  },
};
</script>



<style scoped lang="scss">
.fielList {
  display: flex;
  flex-wrap: wrap;
  p {
    font-size: 14px;
    line-height: 18px;
    margin: 0 80px 10px 0;
    padding: 0 !important;
    color: #333;
    span {
      color: #666;
    }
  }
}

.fielList1 {
  flex-wrap: wrap;
  p {
    font-size: 14px;
    line-height: 18px;
    margin: 0 80px 10px 0;
    padding: 0 !important;
    color: #333;
    span {
      color: #666;
    }
  }
}
</style>
