var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      staticStyle: { padding: "16px" }
    },
    [
      _c("h3", { staticStyle: { "margin-bottom": "50px" } }, [
        _vm._v(_vm._s(_vm.detail.memo))
      ]),
      _c("h4", [_vm._v("工单信息")]),
      _c("div", { staticClass: "fielList" }, [
        _c("p", [
          _c("span", [_vm._v("需求方联系人：")]),
          _vm._v(_vm._s(_vm.detail.demandPersonInfo || "--"))
        ])
      ]),
      _c("div", { staticClass: "fielList" }, [
        _c("p", [
          _c("span", [_vm._v("腾讯工单号：")]),
          _vm._v(_vm._s(_vm.detail.ticketId || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("PO订单号：")]),
          _vm._v(_vm._s(_vm.detail.orderNo || "--"))
        ])
      ]),
      _c("div", { staticClass: "fielList" }, [
        _c("p", [
          _c("span", [_vm._v("订单创建时间：")]),
          _vm._v(_vm._s(_vm.detail.createTime || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("超时时间：")]),
          _vm._v(_vm._s(_vm.detail.slaTime || "--"))
        ])
      ]),
      _c("div", { staticClass: "fielList" }, [
        _c("p", [
          _c("span", [_vm._v("专项(服务项)：")]),
          _vm._v(_vm._s(_vm.detail.svrItem || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("待操作设备数量：")]),
          _vm._v(_vm._s(_vm.detail.num || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("服务信息：")]),
          _vm._v(_vm._s(_vm.detail.serviceInfo || "--"))
        ])
      ]),
      _c("div", { staticClass: "fielList" }, [
        _c("p", [
          _c("span", [_vm._v("园区：")]),
          _vm._v(_vm._s(_vm.detail.campusName || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("一级机房：")]),
          _vm._v(_vm._s(_vm.detail.idcpName || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("机房单元：")]),
          _vm._v(_vm._s(_vm.detail.idcName || "--"))
        ])
      ]),
      _c("div", { staticClass: "fielList" }, [
        _c("p", [
          _c("span", [_vm._v("服务商接口人：")]),
          _vm._v(_vm._s(_vm.detail.servicePersonInfo || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("服务商商务经理：")]),
          _vm._v(_vm._s(_vm.detail.businessManager || "--"))
        ])
      ]),
      _c("h4", [_vm._v("结单信息")]),
      _c("div", { staticClass: "fielList1" }, [
        _c("p", [
          _c("span", [_vm._v("工单结单时间：")]),
          _vm._v(_vm._s(_vm.detail.cEndTime || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("处理工程师名称：")]),
          _vm._v(_vm._s(_vm.detail.engineerName || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("任务实际处理人：")]),
          _vm._v(_vm._s(_vm.detail.cUser || "--"))
        ])
      ]),
      _c("h4", [_vm._v("设备详情")]),
      _c(
        "el-table",
        {
          staticStyle: { width: "1200px" },
          attrs: { border: "", data: _vm.detail.details }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "ticketId", label: "腾讯工单号" }
          }),
          _c("el-table-column", {
            attrs: { prop: "assetId", label: "固资号" }
          }),
          _c("el-table-column", { attrs: { prop: "sn", label: "SN" } }),
          _c("el-table-column", { attrs: { prop: "model", label: "机型" } }),
          _c("el-table-column", { attrs: { prop: "posName", label: "机位" } }),
          _c("el-table-column", { attrs: { prop: "rackName", label: "机架" } }),
          _c("el-table-column", {
            attrs: { prop: "idcName", label: "机房单元" }
          }),
          _c("el-table-column", {
            attrs: { prop: "svrType", label: "设备类型" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }